import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/">Hlavná stránka</a>
                </li>
                <li>
                  <a href="/lieky/">Lieky</a>
                </li>
                <li>
                  <a href="/radca/">Radca</a>
                </li>
                <li>
                  <a href="/upravte_liek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/otazky_a_odpovede/">Otázky a odpovede</a>
                </li>
                <li>
                  <a href="/kde_kupit/">KDE KÚPIŤ </a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Hlavná stránka</a>
            <a href="/radca/#nadcha"> &gt; Radca(NÁDCHA)</a>
            <a href="/radca/nadcha/nasledky_neliecenej_nadchy/" className="sel">
              {" "}
              &gt; Následky neliečenej nádchy
            </a>
          </div>
          <div id="page_content">
            <div className="col1">
              <div className="main_photo">
                <img src="/web/kcfinder/uploads/files/shutterstock_754645981.jpg" />
              </div>
            </div>
            <div className="col2">
              <h1>Následky neliečenej nádchy</h1>
              <div className="wysiwyg">
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span className="s1">
                    <b>
                      Možno sa vám už stalo, že ste išli do práce so škriabaním
                      v hrdle, nádchou alebo silnou bolesťou prinosových dutín a
                      niekoľkokrát ste prekonali prvé príznaky ochorenia skôr,
                      ako sa u vás rozvinulo. Ďalší útok vírusov sa však môže
                      zmeniť na vážne ochorenie. Čo vám môže vtedy hroziť a aké
                      sú následky neliečenej nádchy?{" "}
                    </b>
                  </span>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  Infekčná nádcha je jedným z príznakov sprevádzajúcich{" "}
                  <a href="/slovnik_pojmov/#prechladnutie" target="_blank">
                    prechladnutie
                  </a>
                  &nbsp;alebo chrípku. Prehriatie alebo prefúknutie organizmu
                  vedie k zníženiu odolnosti, čo zas môže viesť k ataku vírusov
                  a vzniku infekcie. Príčinou vzniku zápalu nosovej sliznice
                  môže byť aj pobyt v rovnakej miestnosti ako prechladnutý
                  jedinec. Keď sa náš organizmus infikuje vírusmi, už počas
                  prvých 3 až 4 dní ochorenia môžeme pozorovať príznaky, ako
                  napríklad: upchatý nos, zahlienenie, bolesť hlavy, pnutie v
                  prinosových dutinách alebo aj horúčka. V takom prípade si
                  obvykle zadovážime veľkú dávku liekov proti bolesti a liekov
                  na zníženie horúčky, ktoré síce pomáhajú, ale len dočasne.
                  Ich účinky vedú k ústupu niektorých ťažkostí, zvýšená telesná
                  teplota klesne a budeme sa cítiť lepšie. Tento účinok nás
                  uspokojí, pretože sa nám zdá, že boj s infekciou sa úspešne
                  zavŕšil. Komplikácie po prekonanom prechladnutí však môžu byť
                  skutočne závažné.
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <img
                    alt="Existuje veľa možných komplikácií nádchy. Predíďte im s liekmi OLYNTH®."
                    src="/web/kcfinder/uploads/images/shutterstock_519509851.jpg"
                    style={{
                      width: "380px",
                      height: "253px",
                      "border-width": "2px",
                      "border-style": "solid",
                      float: "right",
                      margin: "5px 0px 10px 10px"
                    }}
                  />
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span className="s1">
                    Jar a jeseň sú obdobia, kedy prudko rastie výskyt{" "}
                    <a href="/slovnik_pojmov/#prechladnutie" target="_blank">
                      prechladnutie
                    </a>
                    &nbsp;a chrípky a ambulancie lekárov čelia návalom chorých.
                    Nádchu často podceňujeme a neliečime, dôsledkom čoho môže
                    byť vznik mnohých komplikácií (napr. zápal stredného ucha,
                    zápal prinosových dutín alebo zápal priedušiek). Upchatý nos
                    a zahlienenie spôsobujú, že dýchame ústami a do tela preniká
                    neohriaty a neprečistený vzduch (riasinky, ktoré čistia
                    vzduch a chránia pred prienikom mikroorganizmov, sú len v
                    nose). Nahromadené hlieny sa môžu bakteriálne infikovať, čo
                    môže viesť k zápalu prinosových dutín, stredného ucha,
                    spojoviek, pľúc a hrtana.{" "}
                  </span>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span className="s1">
                    Zápal stredného ucha býva veľmi častou komplikáciou po
                    prekonanom prechladnutí, a to najmä u detí. Medzi jeho
                    typické príznaky patria: bolesť uší, porucha sluchu,
                    nechutenstvo, niekedy aj hnisavý výtok z ucha a závrat.
                    Vznikajúci zápal oslabuje{" "}
                    <a href="/slovnik_pojmov/#imunitny_system" target="_blank">
                      imunitný systém
                    </a>
                    &nbsp;a vyvoláva subfebrilný stav. Pri nádche preto treba
                    pamätať na správnu hygienu nosa a používanie liekov na
                    uvoľnenie nosa. Pomôžu lieky OLYNTH<sup>®</sup>. Nosové roztokové aerodisperzie{" "}
                    <a href="/lieky/olynth_01/" target="_blank">
                    OLYNTH<sup>®</sup> 0,1 %
                    </a>{" "}
                    ,{" "}
                    <a href="/lieky/olynth_ha_01/" target="_blank">
                      OLYNTH<sup>® </sup>HA 0,1 %
                    </a>{" "}
                    alebo{" "}
                    OLYNTH® PLUS 1 mg/50 mg/ml vďaka obsahu{" "}
                    <a href="/slovnik_pojmov/#xylometazolin" target="_blank">
                      xylometazolínu
                    </a>
                    {" "}rýchlo uvoľňujú nos.{" "}
                  </span>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span className="s1">
                    Mimoriadnu pozornosť počas sezóny prechladnutí treba venovať
                    deťom. Ich{" "}
                    <a href="/slovnik_pojmov/#imunitny_system" target="_blank">
                      imunitný systém
                    </a>
                    &nbsp;ešte nie je plne vyvinutý, malé deti preto bývajú
                    častejšie choré a náchylnejšie na vznik komplikácií. Malé
                    deti sa zároveň nedokážu samé vysmrkať.
                    Dôležité je, že sluchová trubica, ktorá spája stredné ucho s
                    hornou časťou hrdla (nazývaná{" "}
                    <a href="/slovnik_pojmov/#sluchova_trubica" target="_blank">
                      Eustachova trubica
                    </a>
                    ), je po narodení mimoriadne široká, neustále otvorená a
                    umiestnená viac vodorovne ako u dospelého. Takáto anatómia
                    vedie k tomu, že sa po nádche často rozvinie zápal stredného
                    ucha.{" "}
                  </span>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span className="s1">
                  Na zníženie rizika vzniku komplikacie sekundárnej bakteriálnejj{" "}
                    <a href="/slovnik_pojmov/#infekcia" target="_blank">
                      infekcie
                    </a>
                    &nbsp;a komplikácií je pri nádche vhodné používať lieky, ktoré uvoľňujú nos a uľahčujú odstraňovanie hlienov. Dobrým
                    riešením môžu byť aj inhalácie a používanie odsávačky, ktorá
                    umožní odstrániť nahromadené hlieny. V boji proti príznakom
                    infekcie horných dýchacích ciest môžu pomôcť aj lieky{" "}
                    <a href="/lieky/olynth_05/" target="_blank">
                      OLYNTH<sup>®</sup> 0,05 %
                    </a>{" "}
                    alebo{" "}
                    <a href="/lieky/olynth_ha_01/" target="_blank">
                      OLYNTH<sup>®</sup> HA 0,05 %
                    </a> a OLYNTH® PLUS 0,5 mg/50 mg/ml, ktoré zmierňujú opuch nosovej sliznice, pomáhajú telu
                    zbaviť sa nahromadených hlienov v nose a prinosových
                    dutinách a uľahčujú dýchanie nosom pri nádche. Aerodisperzia
                    rozptyľuje jemnú hmlu, ktorá nevyvoláva nepríjemné pocity
                    pri aplikácii. Liek podaný pred spaním prispieva k pokojnej
                    a ničím nerušenej noci.{" "}
                  </span>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px"
                    }}
                  >
                    <span className="s1">Literatúra:</span>
                  </span>
                </p>
                <ol className="ol1">
                  <li
                    className="li1"
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    <span
                      style={{
                        "font-size": "11px"
                      }}
                    >
                      <span className="s1">
                        Emeryk Andrzej, Rapiejko Piotr, Lipiec Agnieszka,{" "}
                        <em>Alergiczny nieżyt nosa – kompendium dla lekarzy</em>
                        , Termedia, 2013./span&gt;
                      </span>
                    </span>
                  </li>
                  <li
                    className="li1"
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    <span
                      style={{
                        "font-size": "11px"
                      }}
                    >
                      <span className="s1">
                        <em>Grypa i przeziębienie. </em>Porady lekarza
                        rodzinnego, opracowanie zbiorowe, Literat, 2012.{" "}
                      </span>
                    </span>
                  </li>
                </ol>
              </div>
              <div className="other_article">
                <p className="name">Ďalšie články</p>
                <a
                  href="/radca/nadcha/ako_si_spravne_cistit_nos/"
                  className="box"
                >
                  <span className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/artykuly/jak_prawidlowo_oczyszczac_nos.png)"}} />
                  <span className="title">
                    Ako si správne čistiť nos pri nádche?
                  </span>
                </a>
                <a href="/radca/nadcha/spoznajte_typy_nadchy/" className="box">
                  <span className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/artykuly/poznaj_rodzaje_kataru.png)"}} />
                  <span className="title">Spoznajte typy nádchy</span>
                </a>
                <a
                  href="/radca/nadcha/zabojujte_s_nadchou_rychlo/"
                  className="box"
                >
                  <span className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/wylecz_katar_szybko_skutecznie.png)"}} />
                  <span className="title">Zabojujte s nádchou rýchlo</span>
                </a>
              </div>
            </div>
            <div className="clear" />
          </div>{" "}
        </div>
        <Footer></Footer>{" "}
        
      </div>
    );
  }
}

export default Page;
